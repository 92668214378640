<template>
  <div class="wrap">
    <div class="facilityBox">
      <!-- form表单 -->
      <el-form class="manageForm" :model="manageForm" :inline="true">
        <el-form-item label="企业名称：" prop="EnterpriseFullName">
          <el-input
            class="fromInp"
            v-model="manageForm.EnterpriseFullName"
            placeholder="输入企业名称"
            @keyup.enter.native="search()"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="LegalPersonlPhone">
          <el-input
            class="fromInp"
            v-model="manageForm.LegalPersonlPhone"
            placeholder="输入手机号"
            @keyup.enter.native="search()"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="提交日期：" prop="TaskDatetime">
          <el-date-picker
            v-model="manageForm.TaskDatetime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd"
            end-placeholder="结束日期"
            @change="search()"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="medium"
            @click="search()"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button
            type="primary"
            size="medium"
            @click="resetForm()"
            icon="el-icon-delete"
            >清空</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="loading"
      >
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50"
          fixed
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseFullName"
          label="企业名称"
          width="200"
          fixed
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseCreditCode"
          label="企业统一社会信用代码"
          width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseAddress"
          label="企业地址"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="LegalPersonIName"
          label="姓名"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="LegalPersonIIDCard"
          label="身份证号"
          width="160"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="LegalPersonlPhone"
          label="手机号"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="CreatedTime"
          label="提交时间"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="UpdatedTime"
          label="变更时间"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="StatusName"
          label="状态"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.StatusName }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="Remark"
          label="备注"
          width="200"
          fixed="right"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-alert
              :title="scope.row.Remark"
              type="warning"
              :closable="false"
              v-if="scope.row.Remark"
            ></el-alert>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" prop="VerifyRemark" label="备注" width="150" show-overflow-tooltip>
                </el-table-column> -->
        <el-table-column align="center" label="操作" width="300" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="setClick(scope.row)"
              icon="el-icon-setting"
              >设置费率
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="goFacility(scope.row, pagination.page)"
              icon="el-icon-document"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px">
        <!-- 分页 -->
        <el-pagination
          background
          class="pagination"
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogVisible" width="50%" class="deep_dialog">
      <span slot="title">设置分成比例</span>
      <el-tabs v-model="rateType" style="margin-top: 1vh">
        <el-tab-pane label="设置费率" name="common"></el-tab-pane>
        <el-tab-pane label="设置专项费率" name="special"></el-tab-pane>
      </el-tabs>
      <div class="dialog_box" style="position: relative">
        <div v-if="rateType == 'common'">
          <el-popover placement="left-start" width="250" trigger="hover">
            <span>温馨提示：</span><br />
            1.请客服查阅并保存平台、代理商之间协议；<br />
            2.确定平台、代理商之间的各类型发票结算费率；<br />
            3.当前费率仅用于保存记录，实际计算线下处理；<br />
            <i
              class="el-icon-question"
              style="
                position: absolute;
                right: 10px;
                top: 5px;
                color: red;
                font-size: 14px;
              "
              slot="reference"
            ></i>
          </el-popover>
          <div class="dialogRole">
            <strong>当前角色：</strong><u>代理商</u><br />
          </div>
          <div class="dialogInfo">
            <div>
              <strong>注册ID：</strong><u>{{ tenant.LegalPersonlPhone }} </u
              ><br />
              <strong>企业名称：</strong><u> {{ tenant.EnterpriseFullName }}</u
              ><br />
              <strong>身份证号码：</strong
              ><u> {{ tenant.LegalPersonIIDCard }}</u
              ><br />
            </div>
            <div>
              <strong>姓名： </strong><u>{{ tenant.LegalPersonIName }} </u
              ><br />
              <strong>电话：</strong><u>{{ tenant.LegalPersonlPhone }} </u
              ><br />
              <strong>信用代码：</strong
              ><u> {{ tenant.EnterpriseCreditCode }}</u
              ><br />
            </div>
          </div>
        </div>
        <div style="margin-bottom: 20px" v-if="rateType == 'special'">
          <span style="font-weight: bold">服务商列表：</span>
          <el-select
            v-model="supplierSelected"
            filterable
            placeholder="请先选择服务商"
            style="width: 300px"
            @change="changeSupplier"
          >
            <el-option
              v-for="item in supplierAllList"
              :key="item.UserID"
              :label="item.EnterpriseFullName"
              :value="item.UserID"
            >
            </el-option>
          </el-select>
        </div>
        <el-table
          :data="
            rateType == 'common'
              ? tenant.eInvoiceTypeList
              : tenant.specialRateList
          "
          :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
          key="rateType"
          v-loading="loading"
          max-height="450px"
        >
          <el-table-column
            align="center"
            prop="EnterpriseAddress"
            label="发票-业务-场景"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span v-if="scope.row.TaskSceneName">
                {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} /
                {{ scope.row.TaskSceneName }}</span
              >
              <span v-else> {{ scope.row.InvoiceTypeName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="平台-服务商结算费率"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-input
                class="fromInp"
                v-model="scope.row.Rate"
                placeholder="输入服务商结算费率（固定扣点）"
                maxlength="5"
                onkeyup="value=value.replace(/[^0-9]+(\.?)+([0-9]{3})$|[^0-9]+(\..?)$|00/,'')"
                oninput="if(value > 99 || value < 0 ){value = ''}"
              >
                <template slot="append">%</template>
              </el-input>
            </template>
          </el-table-column>
        </el-table>
        <div
          style="margin: 20px 0px"
          v-if="rateType == 'special' && extSupplierList.length"
        >
          <div style="font-weight: bold">专项费率</div>
          <el-table
            :data="extSupplierList"
            :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
            max-height="450px"
            :key="rateType"
          >
            <el-table-column
              align="center"
              label="企业名称"
              show-overflow-tooltip
              width="230"
              ><template slot-scope="scope">{{
                scope.row.EnterpriseFullName
              }}</template></el-table-column
            >
            <el-table-column
              align="center"
              label="状态"
              show-overflow-tooltip
              width="100"
            >
              <template slot-scope="scope">
                <el-tag
                  :type="
                    scope.row.Status == 10
                      ? 'primary'
                      : scope.row.Status == 1
                      ? 'danger'
                      : 'success'
                  "
                  >{{ scope.row.StatusName }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="备注"
              show-overflow-tooltip
              width="200"
              ><template slot-scope="scope">{{
                scope.row.Remark
              }}</template></el-table-column
            >
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="small"
                  @click="watchRate(scope.row)"
                  >查看费率</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  @click="editRate(scope.row)"
                  >修改</el-button
                >
                <el-button
                  type="danger"
                  size="small"
                  @click="deleteRate(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top: 10px">
            <!-- 分页 -->
            <el-pagination
              background
              class="pagination"
              @current-change="handleCurrentChange1"
              :current-page.sync="pagination1.page"
              :page-size="pagination1.pagesize"
              layout="total, prev, pager, next, jumper"
              :total="pagination1.total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="rateType = 'special'"
          v-if="rateType == 'common'"
          >下一步</el-button
        >
        <el-button
          type="primary"
          @click="rateType = 'common'"
          v-if="rateType == 'special'"
          >上一步</el-button
        >
        <el-button
          type="primary"
          @click="UpdateSpecialRate()"
          v-if="rateType == 'special'"
          >保存专项费率</el-button
        >
        <el-button
          type="primary"
          @click="UpdateRate()"
          v-if="rateType == 'special'"
          >保存费率</el-button
        >
      </span>
    </el-dialog>
    <el-dialog :visible.sync="itemRateDialog" width="50%">
      <div style="font-weight: bold; text-align: center; margin-bottom: 20px">
        企业名称：{{ itemInfo.EnterpriseFullName }}
      </div>
      <el-table
        :data="itemRateList"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        max-height="450px"
      >
        <el-table-column
          align="center"
          prop="EnterpriseAddress"
          label="发票-业务-场景"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.TaskSceneName">
              {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} /
              {{ scope.row.TaskSceneName }}</span
            >
            <span v-else> {{ scope.row.InvoiceTypeName }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Rate" label="数值">
          <template slot-scope="scope">{{
            scope.row.Rate ? scope.row.Rate + "%" : scope.row.Rate
          }}</template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  AgentList,
  GetAgentRate,
  SetAgentRate,
  GetSupplierAll,
  GetAgentExtRate,
  SetAgentExtRate,
  GetAgentExtSupplier,
  DelAgentExtRate,
} from "@/api/newRate";
export default {
  data() {
    return {
      // 分成模式弹窗
      dialogVisible: false,
      // 分页数据
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      pagination1: {
        page: 1, //当前所处的页码
        pagesize: 5, //每次请求的数量
        total: 0, //总条数
      },
      // 筛选条件数据
      manageForm: {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        TaskDatetime: ["", ""],
      },
      // 数据列博鳌
      tableData: [],
      // 设置状态
      ConfigStatus: 0,
      // 单条数据信息
      tenant: "",
      loading: true,
      rateType: "common",
      supplierAllList: [],
      supplierSelected: "",
      extSupplierList: [],
      currentItem: "",
      itemRateList: [],
      itemInfo: {},
      itemRateDialog: false,
    };
  },
  activated() {
    // 获取列表数据
    this.ByAgentLists();
  },
  created() {
    // 数据列表
    // this.ByAgentList();
  },
  methods: {
    // 设置分成比例
    UpdateRate() {
      const loading = this.$loading({
        lock: true,
        text: "设置中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let commonRateData = [];
      this.tenant.eInvoiceTypeList.forEach((item) => {
        commonRateData.push({
          taskSceneTypeID: item.TaskSceneTypeID,
          rateID: item.RateID,
          rate: item.Rate != 0 && item.Rate != null ? Number(item.Rate) : null,
          tecRate:
            item.TecRate != 0 && item.TecRate != null
              ? Number(item.TecRate)
              : null,
        });
      });
      let data = {
        userID: this.tenant.UserID,
        optionID: this.tenant.OptionID,
        agentRate: commonRateData,
      };
      SetAgentRate(data)
        .then((res1) => {
          this.tenant.eInvoiceTypeList = [];
          this.dialogVisible = false;
          this.$message.success("设置成功");
          this.search();
        })
        .finally(() => {
          loading.close();
        });
    },
    // 保存专项费率
    UpdateSpecialRate() {
      if (!this.supplierSelected) {
        this.$message.warning("请先选择服务商");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "设置中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let specialRateData = [];
      this.tenant.specialRateList.forEach((item) => {
        specialRateData.push({
          taskSceneTypeID: item.TaskSceneTypeID,
          rateID: item.RateID,
          rate: item.Rate != 0 && item.Rate != null ? Number(item.Rate) : null,
          tecRate:
            item.TecRate != 0 && item.TecRate != null
              ? Number(item.TecRate)
              : null,
        });
      });
      let data = {
        userID: this.tenant.UserID,
        optionID: this.currentItem ? this.currentItem.OptionID : "",
        agentRate: specialRateData,
        partnerUserID: this.currentItem
          ? this.currentItem.UserID
          : this.supplierSelected,
      };
      SetAgentExtRate(data)
        .then((res) => {
          this.pagination1.page = 1;
          this.getAgentExtSupplier();
          this.supplierSelected = "";
          this.tenant.specialRateList = [];
          this.$message.success("设置成功");
          this.currentItem = "";
          this.getSupplierAll();
        })
        .finally(() => {
          loading.close();
        });
    },
    // 显示分成比例弹窗
    setClick(item) {
      this.tenant = item;
      this.rateType = "common";
      let commonRatelist = [];
      GetAgentRate({ userid: item.UserID }).then((res) => {
        if (res.data && res.data.length) {
          res.data.forEach((item) => {
            item.Rate = item.Rate == 0 || item.Rate == null ? "" : item.Rate;
            commonRatelist.push(item);
          });
        }
      });
      this.tenant.eInvoiceTypeList = commonRatelist;
      this.supplierSelected = "";
      this.getSupplierAll();
      this.pagination1.page = 1;
      this.getAgentExtSupplier();
      this.dialogVisible = true;
    },
    // 获取服务商列表
    async getSupplierAll() {
      await GetSupplierAll({
        userID: this.tenant.UserID,
      }).then((res) => {
        this.supplierAllList = res.data || [];
      });
    },

    // 选择服务商
    changeSupplier(type) {
      if (type != "edit") {
        this.currentItem = "";
      }
      this.loading = true;
      GetAgentExtRate({
        userID: this.tenant.UserID,
        partnerUserID: this.currentItem
          ? this.currentItem.UserID
          : this.supplierSelected,
      })
        .then((res) => {
          let ratelist = [];
          if (res.data && res.data.length) {
            res.data.forEach((item) => {
              item.Rate = item.Rate == 0 || item.Rate == null ? "" : item.Rate;
              ratelist.push(item);
            });
          }
          this.tenant.specialRateList = ratelist;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 清空搜索条件
    resetForm() {
      this.manageForm = {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        TaskDatetime: ["", ""],
      };
      this.search();
    },
    // 搜索
    search() {
      this.pagination.page = 1;
      this.ByAgentLists();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.search();
    },
    handleCurrentChange1(e) {
      this.pagination1.page = e;
      this.getAgentExtSupplier();
    },
    // 设置服务商分成比例列表
    ByAgentLists() {
      this.loading = true;
      let data = {
        enterpriseFullName: this.manageForm.EnterpriseFullName,
        legalPersonlPhone: this.manageForm.LegalPersonlPhone,
        createdTimeStart:
          this.manageForm.TaskDatetime && this.manageForm.TaskDatetime[0]
            ? this.manageForm.TaskDatetime[0] + " 00:00:00"
            : "",
        createdTimeEnd:
          this.manageForm.TaskDatetime && this.manageForm.TaskDatetime[1]
            ? this.manageForm.TaskDatetime[1] + " 23:59:59"
            : "",
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        status: this.ConfigStatus,
      };
      AgentList(data)
        .then((res) => {
          this.tableData = res.data.DataList;
          this.pagination.total = res.data.TotalCount;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 获取专项费率列表
    getAgentExtSupplier() {
      GetAgentExtSupplier({
        pageSize: this.pagination1.pagesize,
        pageIndex: this.pagination1.page,
        userID: this.tenant.UserID,
        enterpriseFullName: "",
      }).then((res) => {
        this.extSupplierList = res.data.DataList;
        this.pagination1.total = res.data.TotalCount;
      });
    },
    // 修改专项费率
    editRate(item) {
      this.currentItem = item;
      this.supplierSelected = item.EnterpriseFullName;
      this.changeSupplier("edit");
    },
    // 删除专项费率
    deleteRate(item) {
      this.$confirm("确定删除该数据吗？", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        DelAgentExtRate({
          userID: this.tenant.UserID,
          partnerUserID: item.UserID,
        }).then((res) => {
          this.$message.success("删除成功");
          this.pagination1.page = 1;
          this.getAgentExtSupplier();
          this.getSupplierAll();
        });
      });
    },
    watchRate(item) {
      this.itemInfo = item;
      this.loading = true;
      GetAgentExtRate({
        userID: this.tenant.UserID,
        partnerUserID: item.UserID,
      })
        .then((res) => {
          let ratelist = [];
          if (res.data && res.data.length) {
            res.data.forEach((item) => {
              item.Rate = item.Rate == 0 || item.Rate == null ? "" : item.Rate;
              ratelist.push(item);
            });
          }
          this.itemRateList = ratelist;
          this.itemRateDialog = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 详情跳转
    goFacility(item, pageIndex) {
      this.$router.push({
        // path: "/auditInfo/auditAgency/setAgencyInfo", 功能重复,跳转到下面的路径去
        path: "/auditInfo/auditAgency/agencyInfo",
        query: {
          UserID: item.UserID,
          pageIndex,
          title: "待设置",
          isWait: 0,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.wrap {
  .el-input.is-disabled .el-input__inner {
    color: #333;
  }
}
</style>

<style lang="scss" scoped>
.mdoeBox {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 20px;
}

.mdoeDiv {
  width: 80%;
  margin: 10px 0 0;
  line-height: 26px;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10%;
  color: #333;

  span {
    color: red;
    font-size: 16px;
  }
}

.dialog_box {
  font-size: 14px;
  line-height: 26px;
  width: 100%;
  background: #f8f8f8;
  padding: 20px 5%;
  box-sizing: border-box;
  color: #666;
  border-radius: 5px;

  .dialogRole {
    width: 100%;
    text-align: center;
  }

  .dialogInfo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}

.deep_dialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 0px 20px 20px;
  }
}
</style>
